<template>
  <v-text-field
    :label="template.text"
    :value="template.text"
    @keyup.enter="save"
  />
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TemplateItem",

  props: ["template"],

  methods: {
    ...mapActions("templates", ["addTemplateAction", "updateTemplateAction"]),
    save() {
      this.updateResponse(this.template);
    },
  },
};
</script>

<style></style>
