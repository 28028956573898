<template>
  <v-data-table
      :headers="headers"
      :items="getSortedLeads"
      :item-class="itemRowBackground"
      :options="tableOptions"
      :loading="loading"

  >
    <template #top>
      <div class="d-flex">
        <v-checkbox v-model="hideProcessed" label='Hide Processed'>
        </v-checkbox>
        <v-checkbox v-model="hideSent" label='Hide Sent'>
        </v-checkbox>
        <v-checkbox v-model="zoneOnly" label='Zone Only'>
        </v-checkbox>
        <v-checkbox v-model="usOnly" label='US Only'>
        </v-checkbox>
        <v-checkbox v-model="noErrors" label='No Errors'>
        </v-checkbox>
        <v-checkbox v-model="textableOnly" label='Textable Only'>
        </v-checkbox>
      </div>
    </template>
    <template #[`item.approve`]="{ item }">
      <v-btn
          fab
          x-small
          @click="approveItem(item)">
        <v-icon>
          mdi-check
        </v-icon>
      </v-btn>
    </template>
    <template #[`item.delete`]="{ item }">
      <v-btn
          fab
          x-small
          @click="removeItem(item)">
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>

    <template #[`item.domain`]="{ item }">
      <domain-menu :name="item.domain"/>
    </template>

    <template #[`item.sibling`]="{ item }">
      <domain-menu :name="item.sibling"/>
    </template>
  </v-data-table>
</template>

<script>
import {mapGetters, mapActions, mapState} from "vuex";
import DomainMenu from "@/components/DomainMenu.vue";
import tools from "@/util/tools";
import TemplateItem from "@/components/templates/TemplateItem.vue";

export default {
  components: {
    DomainMenu
  },
  data() {
    return {
      dialog: false,
      tableOptions: {
        itemsPerPage: 20
      },
      editedIndex: -1,
      editedItem: {
        text: "",
        timesUsed: 0,
      },
      defaultItem: {
        text: "",
        timesUsed: 0,
      },
      snack: false,
      snackColor: "",
      snackText: "",
      pagination: {},
      headers: [
        {text: "Drop Date", value: "dropDate"},
        {text: "Particle", value: "particle"},
        {text: "Domain", value: "domain"},
        {text: "Sibling", value: "sibling"},
        {text: "Domain POS", value: "domainPos"},
        {text: "Sibling POS", value: "siblingPos"},
        {text: "Sent", value: "sentDate"},
        {text: "Error", value: "error"},
        {text: "Approve", value: "approve"},
        {text: "Remove", value: "delete"},

      ],
    };
  },
  computed: {
    ...mapGetters("leads", ["getSortedLeads"]),
    ...mapState("leads", ["loading", "hideProcessed"]),

    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    hideProcessed: {
      get() {
        return this.$store.state.leads.hideProcessed;
      },
      set(val) {
        this.$store.commit("leads/SET_HIDE_PROCESSED", val);
      },
    },
    hideSent: {
      get() {
        return this.$store.state.leads.hideSent;
      },
      set(val) {
        this.$store.commit("leads/SET_HIDE_SENT", val);
      },
    },
    zoneOnly: {
      get() {
        return this.$store.state.leads.zoneOnly;
      },
      set(val) {
        this.$store.commit("leads/SET_ZONE_ONLY", val);
      },
    },
    usOnly: {
      get() {
        return this.$store.state.leads.usOnly;
      },
      set(val) {
        this.$store.commit("leads/SET_US_ONLY", val);
      },
    },
    noErrors: {
      get() {
        return this.$store.state.leads.noErrors;
      },
      set(val) {
        this.$store.commit("leads/SET_NO_ERRORS", val);
      },
    },
    textableOnly: {
      get() {
        return this.$store.state.leads.textableOnly;
      },
      set(val) {
        this.$store.commit("leads/SET_TEXTABLE_ONLY", val);
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    const searchFilter = {
      dropDate: new Date()
    }
    this.$store.dispatch(`${this.$route.name}/search`, searchFilter)
  },


  methods: {
    ...mapActions({
      updateLead: 'leads/updateLead',
      updateLeadSending: 'leads/updateLeadSending'
    }),

    removeItem(item) {
      item.approvedToSend = false
      this.updateLeadSending(item)
    },
    approveItem(item) {
      item.approvedToSend = true
      this.updateLeadSending(item)
    },

    itemRowBackground(item) {
      if (item.approvedToSend != null) {
        if (item.approvedToSend) return "approved";
        else return "not-approved"
      }
      return "blank"
    },
  },
};
</script>

<style>
.approved {
  background-color: rgb(95, 211, 100)
}

.not-approved {
  background-color: rgb(253, 2, 2)
}

.blank {
  background-color: white;
}


</style>
